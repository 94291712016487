<template>
<div>
    <v-container>
        <v-layout row wrap>
            <h1>Bienvenido al panel de administración (SOLICITANTE)</h1>
        </v-layout>
    </v-container>
    <v-container grid-list-md text-xs-center class="pa-3 elevation-3 white">
        <v-layout>
            <v-flex xs6 row justify-center align-center d-flex>
                <v-img
                   src="/images/actividad/comienza.png"
                   width="50px"
                    ></v-img>
                <div text-xs-left pa-3>
                    <h3 class="text-uppercase">{{ `${this.$store.state.user.nombre} ${this.$store.state.user.app_pat}`}}</h3>
                    <h3 class="text-uppercase">Membresía comienza</h3>
                    <v-btn class="btnsabermas" @click="gosabermas()">
                        <div><h4>SABER MÁS</h4></div>
                        <div><h4>MEMBRESÍA Y ACTIVIDADES </h4></div>
                    </v-btn>
                </div>
            </v-flex>
            <v-flex xs6 >
                <v-container fluid class="fondogris click" @click="vermodalmembresias(1)">
                    <v-layout  row>
                        <v-flex d-flex xs12 md12 lg12  sm3 >
                            <h5 class="text-uppercase">Nivel membresía</h5>
                        </v-flex>
                    </v-layout>
                    <v-layout  row>
                        <v-flex xs4 md4 lg4d-flex  sm3 class="centrar" align-center justify-center  >
                            <v-img
                                src="/images/actividad/comienza.png"
                                width="40px"
                                @click="vermodalmembresias(1)"
                                ></v-img>
                            <div width="100%" class="circleetapa1 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcomienza">comienza</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                                src="/images/actividad/crece.png"
                                width="40px"
                                 @click="vermodalmembresias(2)"
                                ></v-img>
                            <div width="100%" class="circleetapa2 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcrece ">crece</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                                src="/images/actividad/expande.png"
                                width="40px"
                                 @click="vermodalmembresias(3)"
                                ></v-img>
                            <div width="100%" class="circleetapa3 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorexpande  ">expande</h5>
                            </div>
                        </v-flex> 
                    </v-layout>
                </v-container>
                    <v-progress-linear
                        color="progress1"
                        height="25"
                        value="75"
                        class="progressradius"
                        >6 puntos
                        </v-progress-linear>
            </v-flex>
        </v-layout>
        <div v-if=" this.$data.respuesta > 1">
            <v-layout >
                <v-flex xs12 my-3 text-xs-left>
                    <h2 class="text-uppercase">Desafios</h2>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs12 my-3 text-xs-left>
                    <h4 class="text-uppercase">DESAFÍOS TEMA 1: ORGANIZA TUS FINANZAS</h4>
                </v-flex>
            </v-layout>
            <v-layout class="estadosmembresias" row >
                <v-flex xs3 text-xs-center class="pa-3" >
                    <v-card text-xs-center class=" positionrelative centrar text-center pt-3" 
                        :class="{ 'fondodisabled' : result1[0] == 'realizado', 'fondo' : result1[0] != 'realizado'}">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[0] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-etapa2.png"
                            width="100px"
                            ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">PROS Y<br> CONTRAS</h3>
                            </div>
                        </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%" v-if="result1[0] == 'no realizado' " >
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color="fondoprogress1"
                                    height="20"
                                    value="75"
                                    class="progressradius mt-1 mb-1"
                                >+2</v-progress-linear>
                            </v-flex> 
                    </v-card>
                    <v-btn class="my-3 btn-color" v-if="result1[0] != 'realizado' " 
                        :to="{
                            name: 'actividad-organiza-tus-finanzas',
                            params: { etapa: 2 }
                        }">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                    </v-btn>
                    <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos[0])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
                </v-flex>
                <v-flex xs3 text-xs-center class="pa-3" >
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3"
                        :class="{ 'fondodisabled' : result1[1] == 'realizado', 'fondo' : result1[1] != 'realizado'}">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[1] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono.png"
                            width="100px"
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">CUENTAS<br> SEPARADAS</h3>
                                </div>
                            </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%" v-if="result1[1] == 'no realizado' ">
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color="fondoprogress1"
                                    height="20"
                                    value="75"
                                    class="progressradius mt-1 mb-1"
                                >+2</v-progress-linear>
                            </v-flex>    
                    </v-card>
                    <v-btn class="my-3 btn-color" v-if="result1[1] != 'realizado' "  
                    :to="{
                        name: 'actividad-organiza-tus-finanzas',
                        params: { etapa: 2 }
                    }">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                    </v-btn>
                    <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos[1])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
                </v-flex>
                <v-flex xs3 class="pa-3 ">
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3"
                        :class="{ 'fondodisabled' : result1[2] == 'realizado', 'fondo' : result1[2] != 'realizado'}">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[2] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-creacion.png"
                            width="100px"
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">CREACIÓN DE<br>CATEGORÍAS</h3>
                                </div>
                            </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%"  v-if="result1[2] == 'no realizado' ">
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color="fondoprogress1"
                                    height="20"
                                    value="75"
                                    class="progressradius mt-1 mb-1"
                                >+1</v-progress-linear>
                            </v-flex>                   
                    </v-card>
                    <v-btn class="my-3 btn-color" v-if="result1[2] != 'realizado'" 
                    :to="{
                        name: 'actividad-organiza-tus-finanzas',
                        params: { etapa: 3 }
                    }">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                    </v-btn>
                    <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos[2])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
                </v-flex>
                <v-flex xs3 class="pa-3 ">
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3"
                        :class="{ 'fondodisabled' : result1[3] == 'realizado', 'fondo' : result1[3] != 'realizado'}">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[3] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-etapa4.png"
                            width="100px"
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">SEPARACIÓN<br>FINANZAS</h3>
                                </div>
                            </v-card-title>
                        <v-flex class="fondopendientes1 " width="100%" v-if="result1[3] == 'no realizado'" >
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color="fondoprogress1"
                                    height="20"
                                    value="75"
                                    class="progressradius mt-1 mb-1"
                                >+1</v-progress-linear>
                            </v-flex>                       
                    </v-card>
                    <v-btn class="my-3 btn-color" v-if="result1[3] != 'realizado'" 
                    :to="{
                        name: 'actividad-organiza-tus-finanzas',
                        params: { etapa: 4 }
                    }">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                    </v-btn>
                    <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos[3])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-layout>
            <v-flex xs12 my-3 text-xs-left>
                <h4 class="text-uppercase">DESAFÍOS TEMA 2: CONTROLA TUS COSTOS</h4>
            </v-flex>
        </v-layout>
        <v-layout class="estadosmembresias"  row  justify-center>
            <v-flex xs3 text-xs-center class="pa-3">
                 <v-card text-xs-center class=" positionrelative  centrar text-center pt-3"
                 :class="{ 'fondodisabled2' : result2[0] == 'realizado', 'fondopendientes2' : result2[0] != 'realizado'}">
                      <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result2[0] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-aprende.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">APRENDE Y<br>RESPONDE</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes" width="100%"  v-if="result2[0] == 'no realizado'" >
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes"
                                height="25"
                                value="75"
                               class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" v-if="result2[0] != 'realizado'"
                 :to="{
                        name: 'controla-tus-gastos',
                        params: { etapa: 1 }
                    }"
                >
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos2[0])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3">
                <v-card text-xs-center class=" positionrelative centrar text-center pt-3"
                :class="{ 'fondodisabled2' : result2[1] == 'realizado', 'fondopendientes2' : result2[1] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result2[1] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-registro.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">FOTO REGISTRO<br>DE GASTOS</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes " width="100%"  
                                v-if="result2[1] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes"
                                height="25"
                                value="75"
                                class="progressradius mt-1 mb-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" v-if="result2[1] != 'realizado'" 
                    :to="{
                        name: 'controla-tus-gastos',
                        params: { etapa: 2 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos2[1])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative centrar text-center pt-3 " 
                 :class="{ 'fondodisabled2' : result2[2] == 'realizado', 'fondopendientes2' : result2[2] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result2[2] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-productos.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">¿QUÉ DEJASTE DE VENDER?</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes" width="100%"  v-if="result2[2] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes"
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" v-if="result2[2] != 'realizado'" 
                :to="{
                        name: 'controla-tus-gastos',
                        params: { etapa: 3 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos2[2])">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative  centrar text-center pt-3 "
                 :class="{ 'fondodisabled2' : result2[3] == 'realizado', 'fondopendientes2' : result2[3] != 'realizado'}">
                     <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result2[3] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-producto.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">PRODUCTO<br>ESTRELLA</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes " width="100%"  v-if="result2[3] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes"
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" v-if="result2[3] != 'realizado'"
                    :to="{
                        name: 'controla-tus-gastos',
                        params: { etapa: 4 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos2[3])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12 my-3 text-xs-left>
                <h4 class="text-uppercase">DESAFÍOS TEMA 3: PLANIFICA TU FUTURO</h4>
            </v-flex>
        </v-layout>
        <v-layout class="estadosmembresias"  row  justify-center>
            <v-flex xs3 text-xs-center class="pa-3">
                 <v-card text-xs-center class=" positionrelative  centrar text-center pt-3"
                 :class="{ 'fondodisabled3' : result3[0] == 'realizado', 'fondopendientes3' : result3[0] != 'realizado'}">
                      <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result3[0] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-metas.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">METAS A<br>CORTO PLAZO</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress3" width="100%"  v-if="result3[0] == 'no realizado'" >
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes3"
                                height="25"
                                value="75"
                               class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes3" v-if="result3[0] != 'realizado'" 
                    :to="{
                        name: 'metas-a-corto-plazo',
                        params: { etapa: 1 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else @click="informacionmodal(listadodatos3[0])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3">
                <v-card text-xs-center class=" positionrelative centrar text-center pt-3"
                :class="{ 'fondodisabled3' : result3[1] == 'realizado', 'fondopendientes3' : result3[1] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result3[1] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-metas-largo-plazo.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">METAS A<br>LARGO PLAZO</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress3 " width="100%"  
                                v-if="result3[1] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">realizar actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes3"
                                height="25"
                                value="75"
                                class="progressradius mt-1 mb-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes3" v-if="result3[1] != 'realizado'"
                :to="{
                        name: 'metas-a-corto-plazo',
                        params: { etapa: 2 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos3[1])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative centrar text-center pt-3 " 
                 :class="{ 'fondodisabled3' : result3[2] == 'realizado', 'fondopendientes3' : result3[2] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result3[2] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-recordatorio.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">RECORDATORIO<br>DE OBJETIVOS</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress3" width="100%"  v-if="result3[2] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes3"
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes3" v-if="result3[2] != 'realizado'"
                    :to="{
                        name: 'metas-a-corto-plazo',
                        params: { etapa: 3 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos3[2])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative  centrar text-center pt-3 "
                 :class="{ 'fondodisabled3' : result3[3] == 'realizado', 'fondopendientes3' : result3[3] != 'realizado'}">
                     <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result3[3] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-objetivo.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">OBJETIVOS DE<br> INVERSIÓN</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress3 " width="100%"  v-if="result3[3] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes3"
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes3" v-if="result3[3] != 'realizado'" 
                :to="{
                        name: 'metas-a-corto-plazo',
                        params: { etapa: 4 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos3[3])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12 my-3 text-xs-left>
                <h4 class="text-uppercase">DESAFÍOS TEMA 4: GESTIONA TU DEUDA</h4>
            </v-flex>
        </v-layout>
        <v-layout class="estadosmembresias"  row  >
            <v-flex xs3 text-xs-center class="pa-3">
                 <v-card text-xs-center class=" positionrelative  centrar text-center pt-3"
                 :class="{ 'fondodisabled4' : result4[0] == 'realizado', 'fondopendientes4' : result4[0] != 'realizado'}">
                      <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result4[0] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-calculadora.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">CALCULA % PAGO<br>DE DEUDAS</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress4" width="100%"  v-if="result4[0] == 'no realizado'" >
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes4"
                                height="25"
                                value="75"
                               class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                        
                </v-card>
                <v-btn class="my-3 btn-colorpendientes4" v-if="result4[0] != 'realizado'"
                :to="{
                        name: 'gestiona-tu-deuda',
                        params: { etapa: 1 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos4[0])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3">
                <v-card text-xs-center class=" positionrelative centrar text-center pt-3"
                :class="{ 'fondodisabled4' : result4[1] == 'realizado', 'fondopendientes4' : result4[1] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result4[1] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-financiamiento.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">FINANCIAMIENTO<br>DE CRÉDITO</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress4 " width="100%"  
                                v-if="result4[1] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes4"
                                height="25"
                                value="75"
                                class="progressradius mt-1 mb-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes4" v-if="result4[1] != 'realizado'"
                  :to="{
                        name: 'gestiona-tu-deuda',
                        params: { etapa: 2 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos4[1])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative centrar text-center pt-3 " 
                 :class="{ 'fondodisabled4' : result4[2] == 'realizado', 'fondopendientes4' : result4[2] != 'realizado'}">
                    <v-img
                        src="/images/actividad/realizado.png"
                        width="100px"
                        class="positionabsolutedashboard"
                        v-if="result4[2] == 'realizado' "
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-regla.png"
                        width="100px"
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">REGLAS<br>DE CRÉDITO</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondoprogress4" width="100%"  v-if="result4[2] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color="fondopendientes4"
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes4" v-if="result4[2] != 'realizado'"
                  :to="{
                        name: 'gestiona-tu-deuda',
                        params: { etapa: 3 }
                    }">
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> realizar actividad</h3>
                </v-btn>
                <v-btn class="my-3 btn-color" v-else  @click="informacionmodal(listadodatos4[2])">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold">Ver resultados</h3>
                    </v-btn>
            </v-flex>
        </v-layout>
        </div>
        <div v-else>
            <v-container >
                <v-layout row wrap>
                    <v-flex xs6 offset-xs3>
                        <v-img
                         src="/images/actividad/sinregistros.png">
                        </v-img>
                    </v-flex>
                    <v-flex xs12 class="pa-3" offset-xs-1>
                        <h4 class="text-uppercase">UPS! TODAVÍA NO HAS REALIZADO NINGÚN DESAFÍO</h4>
                    </v-flex>
                </v-layout>
                 <v-layout>
                <v-flex xs12 my-3 text-xs-left>
                    <h4 class="text-uppercase">DESAFÍOS TEMA 1: ORGANIZA TUS FINANZAS</h4>
                </v-flex>
            </v-layout>
            <v-layout class="estadosmembresias" row >
                <v-flex xs3 text-xs-center class="pa-3" >
                    <v-card text-xs-center class=" positionrelative centrar text-center pt-3" 
                        :class="{ 'fondodisabled' : result1[0] == 'realizado', 'fondo' : result1[0] != 'realizado'}">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[0] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-etapa2.png"
                            width="100px"
                            ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">PROS Y<br> CONTRAS</h3>
                            </div>
                        </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%" v-if="result1[0] == 'no realizado' " >
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color="fondoprogress1"
                                    height="20"
                                    value="75"
                                    class="progressradius"
                                >+1</v-progress-linear>
                            </v-flex> 
                    </v-card>
                    <v-btn class="my-3 btn-color"  to="/actividad/organiza" :disabled="result1[0] == 'realizado'  ">
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> REALIZAR ACTIVIDAD</h3>
                    </v-btn>
                    
                </v-flex>
                <v-flex xs3 text-xs-center class="pa-3" >
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3" style="background: #F0F0F0"
                        >
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[1] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono.png"
                            width="100px"
                            style="filter: grayscale(1); "
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">CUENTAS<br> SEPARADAS</h3>
                                </div>
                            </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%" v-if="result1[1] == 'no realizado' " style="background:#E4E4E4">
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color=""
                                    height="20"
                                    value="75"
                                    class="progressradius" style="background: #F0F0F0 !important"
                                >+1</v-progress-linear>
                            </v-flex>    
                    </v-card>
                    <v-btn class="my-3 btn-color" disabled>
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                    </v-btn>
                </v-flex>
                <v-flex xs3 class="pa-3 ">
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3"
                   style="background: #F0F0F0">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[2] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-creacion.png"
                            width="100px"
                            style="filter: grayscale(1); "
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">CREACIÓN DE<br>CATEGORÍAS</h3>
                                </div>
                            </v-card-title>
                            <v-flex class="fondopendientes1 " width="100%"  v-if="result1[2] == 'no realizado' "  style="background:#E4E4E4">
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color=""
                                    height="20"
                                    value="75"
                                    class="progressradius "  style="background: #F0F0F0 !important"
                                >+1</v-progress-linear>
                            </v-flex>                   
                    </v-card>
                    <v-btn class="my-3 btn-color" disabled>
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                    </v-btn>
                </v-flex>
                <v-flex xs3 class="pa-3 ">
                    <v-card text-xs-center class="positionrelative centrar text-center pt-3"
                       style="background: #F0F0F0">
                        <v-img
                            src="/images/actividad/realizado.png"
                            width="100px"
                            class="positionabsolutedashboard"
                            v-if="result1[3] == 'realizado' "
                            ></v-img>
                        <v-img
                            src="/images/actividad/icono-etapa4.png"
                            width="100px"
                             style="filter: grayscale(1); "
                            ></v-img>
                            <v-card-title primary-title text-xs-center>
                                <div>
                                    <h3 class="headline mb-0 text-uppercase">SEPARACIÓN<br>FINANZAS</h3>
                                </div>
                            </v-card-title>
                        <v-flex class="fondopendientes1 " width="100%" v-if="result1[3] == 'no realizado'"  style="background:#E4E4E4">
                                    <div>
                                        <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                    </div>
                                <v-progress-linear
                                    color=""
                                    height="20"
                                    value="75"
                                    class="progressradius"  style="background: #F0F0F0 !important"
                                >+1</v-progress-linear>
                            </v-flex>                       
                    </v-card>
                    <v-btn class="my-3 btn-color" disabled>
                        <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-layout>
        <v-flex xs12 my-3 text-xs-left>
                <h1 class="text-uppercase">próximos desafíos</h1>
            </v-flex>
        </v-layout>
        <v-layout>
        <v-flex xs12 my-3 text-xs-left>
                <h4 class="text-uppercase">DESAFÍOS TEMA 2: CONTROLA TUS COSTOS</h4>
            </v-flex>
        </v-layout>
        <v-layout class="estadosmembresias"  row  justify-center style="position:relative">
            <div class="pa-3 capagrisdesafios">
                <h3 class="text-uppercase">desafíos disponibles a partir de la próxima semana</h3>
            </div>
            <v-flex xs3 text-xs-center class="pa-3">
                 <v-card text-xs-center class=" positionrelative  centrar text-center pt-3"
                 style="background: #F0F0F0">
                    <v-img
                        src="/images/actividad/icono-aprende.png"
                        width="100px"
                        style="filter: grayscale(1); "
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">APRENDE Y<br>RESPONDE</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes" width="100%"  v-if="result2[0] == 'no realizado'"  style="background:#E4E4E4">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color=""
                                height="25"
                                value="75"
                               class="progressradius mb-1 mt-1" style="background: #F0F0F0 !important"
                            >+1</v-progress-linear>
                        </v-flex>
                        
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" disabled>
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3">
                <v-card text-xs-center class=" positionrelative centrar text-center pt-3"  style="background: #F0F0F0"
               >
                    <v-img
                        src="/images/actividad/icono-registro.png"
                        width="100px"
                         style="filter: grayscale(1); "
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">FOTO REGISTRO<br>DE GASTOS</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes " width="100%"  style="background:#E4E4E4" 
                                v-if="result2[1] == 'no realizado'">
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color=""
                                height="25"
                                value="75"
                                class="progressradius mt-1 mb-1" style="background: #F0F0F0 !important"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" disabled>
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative centrar text-center pt-3 " 
                   style="background: #F0F0F0">

                    <v-img
                        src="/images/actividad/icono-productos.png"
                        width="100px" style="filter: grayscale(1); "
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">¿QUÉ DEJASTE DE VENDER?</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes" width="100%"  v-if="result2[2] == 'no realizado'" style="background:#E4E4E4" >
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color=""
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1" style="background: #F0F0F0 !important"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" disabled>
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                </v-btn>
            </v-flex>
            <v-flex xs3 class="pa-3 ">
                 <v-card text-xs-center class="positionrelative  centrar text-center pt-3 "
                 style="background: #F0F0F0">

                    <v-img
                        src="/images/actividad/icono-producto.png"
                        width="100px" style="filter: grayscale(1); "
                        ></v-img>
                        <v-card-title primary-title text-xs-center>
                            <div>
                                <h3 class="headline mb-0 text-uppercase">PRODUCTO<br>ESTRELLA</h3>
                            </div>
                        </v-card-title>
                        <v-flex class="fondopuntospendientes " width="100%"  v-if="result2[3] == 'no realizado'" style="background:#E4E4E4" >
                                <div>
                                    <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                                </div>
                            <v-progress-linear
                                color=""
                                height="25"
                                value="75"
                                class="progressradius mb-1 mt-1" style="background: #F0F0F0 !important"
                            >+1</v-progress-linear>
                        </v-flex>
                </v-card>
                <v-btn class="my-3 btn-colorpendientes" disabled>
                    <h3 class=" mb-0 text-uppercase colorblanco font-weight-bold"> Ver resultados</h3>
                </v-btn>
            </v-flex>
        </v-layout>
        
            </v-container>
        </div>
        <v-container>
            <v-layout>
                
            <v-flex xs12 my-3 text-xs-left>
                <h2 class="text-uppercase">beneficios</h2>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs-4 text-xs-center centrar>
                 <v-img
                        src="/images/actividad/beneficios/beneficio1.png"
                        width="120px"
                        ></v-img>
                    <div>
                        <h4 class="text-uppercase ">CHAT WHATSAPP GRUPAL <br>NIVEL COMIENZA</h4>
                    </div>
            </v-flex>
            <v-flex xs-4 centrar>
                 <v-img
                        src="/images/actividad/beneficios/beneficio1-2.png"
                        width="120px"
                        ></v-img>
                        <div>
                            <h4 class="text-uppercase ">RECORDATORIOS FINANCIEROS<br>PERSONALIZADOS</h4>
                        </div>
            </v-flex>
            <v-flex xs-4 centrar>
                 <v-img
                        src="/images/actividad/beneficios/beneficio1-3.png"
                        width="120px"
                        ></v-img>
                        <div>
                            <h4 class="text-uppercase colorbeneficiosdisabled">MATERIAL CON TIPS<br>PARA EL NEGOCIO</h4>
                        </div>
            </v-flex>
        </v-layout>
        </v-container>
    </v-container>
    <v-dialog v-model="dialog3" full-width="false"  width="800">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(3)">
          x
      </v-btn>
            <h1 class="pa-3">MEMBRESÍAS</h1>
        <v-container>
            <v-layout>
                <v-flex xs4 class="pa-2">
                    <v-card class="pa-2 centrar circleetapamembresia1 fondogrismembresia">
                        <v-img
                            src="/images/actividad/comienza.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia1">COMIENZA</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia1"
                                        height="25"
                                        value="40"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px">0 a 9 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-btn class="colormembresia1"  @click="verbeneficios(3)">
                                VER BENEFICIOS
                            </v-btn>
                    </v-card>
                </v-flex>
                <v-flex xs4 class="pa-2">
                      <v-card class="pa-2 centrar  circleetapamembresia2 fondogrismembresia">
                        <v-img
                            src="/images/actividad/crece.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia2">CRECE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia2"
                                        height="25"
                                        value="80"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 20px 2px 0px;text-align:center">9 a 12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-btn class="colormembresia2"  @click="verbeneficios(3)">
                                VER BENEFICIOS
                            </v-btn>
                    </v-card>
            </v-flex>
                <v-flex xs4 class="pa-2">
                     <v-card class="pa-2 centrar  circleetapamembresia3 fondogrismembresia">
                        <v-img
                            src="/images/actividad/expande.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia3">EXPANDE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia3"
                                        height="25"
                                        value="100"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px;text-align:center">12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-btn class="colormembresia3" @click="verbeneficios(3)">
                                VER BENEFICIOS
                            </v-btn>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog4" full-width="false"  width="800">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(4)">
          x
      </v-btn>
            <h1 class="pa-3">MEMBRESÍAS</h1>
        <v-container>
            <v-layout>
                <v-flex xs4 class="pa-2">
                    <v-card class="pa-2 centrar circleetapamembresia1 fondogrismembresia">
                        <v-img
                            src="/images/actividad/comienza.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia1">COMIENZA</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia1"
                                        height="25"
                                        value="40"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px">0 a 9 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom1">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Comienza</h5></li>
                                     <li><h5>Recordatorios financieros personalizados</h5></li>
                                      <li><h5>Material con tips para el negocio según nivel</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs4 class="pa-2">
                      <v-card class="pa-2 centrar  circleetapamembresia2 fondogrismembresia">
                        <v-img
                            src="/images/actividad/crece.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia2">CRECE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia2"
                                        height="25"
                                        value="80"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 20px 2px 0px;text-align:center">9 a 12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom2">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Crece</h5></li>
                                     <li><h5>Recordatorios financieros personalizados</h5></li>
                                      <li><h5>Material con tips para el negocio según nivel</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
            </v-flex>
                <v-flex xs4 class="pa-2">
                     <v-card class="pa-2 centrar  circleetapamembresia3 fondogrismembresia">
                        <v-img
                            src="/images/actividad/expande.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia3">EXPANDE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia3"
                                        height="25"
                                        value="100"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px;text-align:center">12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                           <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom3">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Expande</h5></li>
                                     <li><h5>Recordatorios financieros personalizados</h5></li>
                                      <li><h5>Material con tips para el negocio según nivel</h5></li>
                                      <li><h5>Certificado nivel más alto</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog5" full-width="false"  width="550">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(5)">
          x
            </v-btn>
        <v-container>
            <v-layout row wrap>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-left">
                    <div class="boredermembresiasbotom1">
                        <h2 class="text-uppercase font-weight-light">{{ datosmodal.tema}}</h2>
                        <h2 class="text-uppercase font-weight-light">Actividad: {{ datosmodal.actividad}}</h2>
                    </div>
                    <div class="pt-3 pb-3">
                        <h3>Descripción:</h3>
                        <h4>Tipo Actividad: <b> {{datosmodal.tipo }}</b></h4>
                        <h4>Fecha realización: <b> {{datosmodal.fecha }}</b></h4>
                        
                    </div>
                    
                </v-flex>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-center fondopendientes1">
                        <div>
                            <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                        </div>
                    <v-progress-linear
                        color="fondoprogress1"
                        height="20"
                        value="40"
                        class="progressradius mt-1 mb-1 text-xs-left"
                    ><div class="pl-3">+1</div></v-progress-linear>
                    </v-flex>
                            
            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog6" full-width="false"  width="550">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(6)">
          x
            </v-btn>
        <v-container>
            <v-layout row wrap>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-left">
                    <div class="boredermembresiasbotom3">
                        <h2 class="text-uppercase font-weight-light">{{ datosmodal.tema}}</h2>
                        <h2 class="text-uppercase font-weight-light">Actividad: {{ datosmodal.actividad}}</h2>
                    </div>
                    <div class="pt-3 pb-3">
                        <h3>Descripción:</h3>
                        <h4>Tipo Actividad: <b> {{datosmodal.tipo }}</b></h4>
                        <h4>Fecha realización: <b> {{datosmodal.fecha }}</b></h4>
                    </div>
                </v-flex>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-center fondopuntospendientes">
                        <div>
                            <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                        </div>
                    <v-progress-linear
                        color="fondopendientes"
                        height="20"
                        value="40"
                        class="progressradius mt-1 mb-1 text-xs-left"
                    ><div class="pl-3">+1</div></v-progress-linear>
                    </v-flex>      
            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog7" full-width="false"  width="550">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(7)">
          x
            </v-btn>
        <v-container>
            <v-layout row wrap>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-left">
                    <div class="boredermembresiasbotom2">
                        <h2 class="text-uppercase font-weight-light">{{ datosmodal.tema}}</h2>
                        <h2 class="text-uppercase font-weight-light">Actividad: {{ datosmodal.actividad}}</h2>
                    </div>
                    <div class="pt-3 pb-3">
                        <h3>Descripción:</h3>
                        <h4>Tipo Actividad: <b> {{datosmodal.tipo }}</b></h4>
                        <h4>Fecha realización: <b> {{datosmodal.fecha }}</b></h4>
                    </div>
                </v-flex>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-center fondoprogress3">
                        <div>
                            <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                        </div>
                    <v-progress-linear
                        color="fondopendientes3"
                        height="20"
                        value="40"
                        class="progressradius mt-1 mb-1 text-xs-left"
                    ><div class="pl-3">+1</div></v-progress-linear>
                    </v-flex>   
  
            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog8" full-width="false"  width="550">
        <v-card class="text-xs-center" style="position: relative;">
            <v-btn class="btnclose" @click="cerrarmodal(8)">
          x
            </v-btn>
        <v-container>
            <v-layout row wrap>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-left">
                    <div class="boredermembresiasbotom4">
                        <h2 class="text-uppercase font-weight-light">{{ datosmodal.tema}}</h2>
                        <h2 class="text-uppercase font-weight-light">Actividad: {{ datosmodal.actividad}}</h2>
                    </div>
                    <div class="pt-3 pb-3">
                        <h3>Descripción:</h3>
                        <h4>Tipo Actividad: <b> {{datosmodal.tipo }}</b></h4>
                        <h4>Fecha realización: <b> {{datosmodal.fecha }}</b></h4>
                    </div>
                </v-flex>
                <v-flex xs10 offset-xs1 class="pa-2 text-xs-center fondoprogress4">
                        <div>
                            <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                        </div>
                    <v-progress-linear
                        color="fondopendientes4"
                        height="20"
                        value="40"
                        class="progressradius mt-1 mb-1 text-xs-left"
                    ><div class="pl-3">+1</div></v-progress-linear>
                    </v-flex>   

            </v-layout>
        </v-container>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { Component, Prop,Vue } from "vue-property-decorator";
import {
  Configuration,
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import API from "@/api";
import { EventBus } from "@/main";
import CurrencyInput from "@/components/Currency.vue";
import DniPerson from "@/components/DniField.vue";
import DniBusiness from "@/components/DniFieldBusiness.vue";
import { isRutValid } from "@/utils";
import { mapState } from "vuex";
import Axios from "axios";

@Component({
    data() {
      return {
            e1: 1,
            contador:null,
            dialog1:false,
            dialog2:false,
            dialog3:false,
            dialog4:false,
            dialog5:false,
            dialog6:false,
            dialog7:false,
            dialog8:false,
            actividades:[11,12,13,14,21,22,23,24,31,32,33,34,41,42,43,44],
            respuesta:[],
            realizados1:[],
            realizados2:[],
            realizados3:[],
            realizados4:[],
            r1:[11,12,13,14],
            r2:[21,22,23,24],
            r3:[31,32,33,34],
            r4:[41,42,43,44],
            result1:[],
            result2:[],
            result3:[],
            result4:[],
            listados:[],
            listadodatos:[{
                            tema:'tema 1: ORGANIZA TUS FINANZAS',
                            actividad: 'PROS Y CONTRAS (1/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:11,
                            indice:0
                        },{
                            tema:'tema 1: ORGANIZA TUS FINANZAS',
                            actividad: 'CUENTAS SEPARADAS (2/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:12,
                            indice:1
                        },{
                            tema:'tema 1: ORGANIZA TUS FINANZAS',
                            actividad: 'CREACIÓN DE CATEGORÍAS (3/4)',
                            tipo: 'Firmas',
                            fecha:null,
                            id:13,
                            indice:2
                        },{
                            tema:'tema 1: ORGANIZA TUS FINANZAS',
                            actividad: 'SEPARACIÓN FINANZAS (4/4)',
                            tipo: 'Selección',
                            fecha:null,
                            id:14,
                            indice:3
                        }],
            listadodatos2:[{
                            tema:'tema 2: CONTROLA TUS COSTOS',
                            actividad: 'APRENDE Y RESPONDE (1/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:21,
                            indice:0
                        },{
                            tema:'tema 2: CONTROLA TUS COSTOS',
                            actividad: 'EXPLICA CÓMO ORDENAS TU DINERO (2/4)',
                            tipo: 'Imagen',
                            fecha:null,
                            id:22,
                            indice:1
                        },{
                            tema:'tema 2: CONTROLA TUS COSTOS',
                            actividad: '¿QUÉ PRODUCTOS DEJASTE DE VENDER? (3/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:23,
                            indice:2
                        },{
                            tema:'tema 2: CONTROLA TUS COSTOS',
                            actividad: 'PRODUCTO ESTRELLA (4/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:24,
                            indice:3
                        }],
            listadodatos3:[{
                            tema:'tema 3: PLANIFICA TU FUTURO',
                            actividad: 'METAS A CORTO PLAZO (1/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:31,
                            indice:0
                        },{
                            tema:'tema 3: PLANIFICA TU FUTURO',
                            actividad: 'METAS A LARGO PLAZO (2/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:32,
                            indice:1
                        },{
                            tema:'tema 3: PLANIFICA TU FUTURO',
                            actividad: 'RECORDATORIO DE OBJETIVOS (3/4)',
                            tipo: 'Imagen',
                            fecha:null,
                            id:33,
                            indice:2
                        },{
                            tema:'tema 3:PLANIFICA TU FUTURO',
                            actividad: 'OBJETIVOS DE INVERSIÓN DEL NEGOCIO (4/4)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:34,
                            indice:3
                        }],
            listadodatos4:[{
                            tema:'tema 4: ORGANIZA TUS FINANZAS',
                            actividad: 'PROS Y CONTRAS (1/3)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:41,
                            indice:0
                        },{
                            tema:'tema 4: ORGANIZA TUS FINANZAS',
                            actividad: 'CUENTAS SEPARADAS (2/3)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:42,
                            indice:1 
                        },{
                            tema:'tema 4: ORGANIZA TUS FINANZAS',
                            actividad: 'CREACIÓN DE CATEGORÍAS (3/3)',
                            tipo: 'Comenta',
                            fecha:null,
                            id:43,
                            indice:2
                        }],
            datosmodal:{
                tema:null,
                actividad:null,
                tipo:null,
                fecha:null
            }

            /*realizados1:[{
                activ:{
                    ac:{
                        icono:'icono-etapa2.png',
                        srcimg:'/images/actividad/',
                        titulo:'PROS Y CONTRAS',

                    }
                }
            }]*/
        }
    },methods:{
        verbeneficios(numbeneficio){
            this.$data.dialog4 = true 
        },
        vermodalmembresias(numeromodalmembresia){
            this.$data.dialog3 = true
        },
        gosabermas(){
            this.$router.push({
                path: "/actividad/sabermas"
            })
        },
        cerrarmodal(modal){
            if(modal == 1){
                this.$data.dialog1 = false
            }else if(modal == 2){
                this.$data.dialog2 = false
            }
            else if(modal == 3){
                this.$data.dialog3 = false
            }
            else if(modal == 4){
                this.$data.dialog4 = false
            }else if(modal==5){
                this.$data.dialog5 = false
            }else if(modal==6){
                this.$data.dialog6 = false
            }else if(modal==7){
                this.$data.dialog7 = false
            }else if(modal==8){
                this.$data.dialog8 = false
            }
        },informacionmodal(datos){
           if((datos.id > 10 )&& (datos.id < 15)){
               this.$data.listados.data.data.forEach((el,i)=>{
                if(el.cta_list_id == datos.id){
                    this.$data.listadodatos[datos.indice].fecha=el.created_at;
                }
            })
            //@ts-ignore
                this.desplegarmodal(this.$data.listadodatos[datos.indice])
           }else if((datos.id > 20 )&& (datos.id < 25)){
               this.$data.listados.data.data.forEach((el,i)=>{
                if(el.cta_list_id == datos.id){
                    this.$data.listadodatos2[datos.indice].fecha=el.created_at;
                }
            })
                this.desplegarmodal(this.$data.listadodatos2[datos.indice])
           }else if((datos.id > 30 )&& (datos.id < 35)){
               this.$data.listados.data.data.forEach((el,i)=>{
                if(el.cta_list_id == datos.id){
                    this.$data.listadodatos3[datos.indice].fecha=el.created_at;
                }
            })
                this.desplegarmodal(this.$data.listadodatos3[datos.indice])
           }else if((datos.id > 40 )&& (datos.id < 45)){
               this.$data.listados.data.data.forEach((el,i)=>{
                if(el.cta_list_id == datos.id){
                    this.$data.listadodatos4[datos.indice].fecha=el.created_at;
                }
            })
                this.desplegarmodal(this.$data.listadodatos4[datos.indice])
           }
        },
        desplegarmodal(activdad){
            console.log(activdad)
            if((activdad.id > 10 )&& (activdad.id < 15)){
                this.$data.datosmodal.tema = activdad.tema;
                this.$data.datosmodal.actividad= activdad.actividad
                this.$data.datosmodal.tipo = activdad.tipo
                this.$data.datosmodal.fecha = activdad.fecha
                this.$data.dialog5=true
            }else if((activdad.id > 20 )&& (activdad.id < 25)){
                this.$data.datosmodal.tema = activdad.tema;
                this.$data.datosmodal.actividad= activdad.actividad
                this.$data.datosmodal.tipo = activdad.tipo
                this.$data.datosmodal.fecha = activdad.fecha
                this.$data.dialog6=true
            }else if((activdad.id > 30 )&& (activdad.id < 35)){
                this.$data.datosmodal.tema = activdad.tema;
                this.$data.datosmodal.actividad= activdad.actividad
                this.$data.datosmodal.tipo = activdad.tipo
                this.$data.datosmodal.fecha = activdad.fecha
                this.$data.dialog7=true
            }else if((activdad.id > 40 )&& (activdad.id < 45)){
                this.$data.datosmodal.tema = activdad.tema;
                this.$data.datosmodal.actividad= activdad.actividad
                this.$data.datosmodal.tipo = activdad.tipo
                this.$data.datosmodal.fecha = activdad.fecha
                this.$data.dialog8=true
            }
        }
    },async beforeMount(){
        API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
        .then((resolve)=>{
            console.log(resolve)
            this.$data.listados = resolve;
            if((resolve.data.data.length >= 9) && (resolve.data.data.length < 12)){
                this.$data.dialog1 = true;
            }
            if((resolve.data.data.length >= 12) && (resolve.data.data.length < 15)){
                this.$data.dialog2 = true;
            }
            for(let i=0; i < this.$data.actividades.length ;i++){
                for(let j=0; j < resolve.data.data.length ;j++){
                    if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                        if(this.$data.actividades[i] < 15){
                            this.$data.realizados1.push(this.$data.actividades[i])
                        }else if(this.$data.actividades[i] < 25){
                            this.$data.realizados2.push(this.$data.actividades[i])
                        }else if(this.$data.actividades[i] < 35){
                            this.$data.realizados3.push(this.$data.actividades[i])
                        }else if(this.$data.actividades[i] < 45){
                            this.$data.realizados4.push(this.$data.actividades[i])
                        }
                    }
                }
            }
            this.$data.realizados1 = Array.from(new Set(this.$data.realizados1))
            this.$data.realizados2 = Array.from(new Set(this.$data.realizados2))
            this.$data.realizados3 = Array.from(new Set(this.$data.realizados3))
            this.$data.realizados4 = Array.from(new Set(this.$data.realizados4))

            this.$data.contador = this.$data.realizados1.length + this.$data.realizados2.length + this.$data.realizados3.length + this.$data.realizados4.length;

            console.log(this.$data.contador)
            this.$data.r1.forEach((el,i)=>{
                if(this.$data.realizados1.indexOf(el) > -1){
                    this.$data.result1.push('realizado')
                }else{
                    this.$data.result1.push('no realizado')
                }
            })
            this.$data.r2.forEach((el,i)=>{
                if(this.$data.realizados2.indexOf(el) > -1){
                    this.$data.result2.push('realizado')
                }else{
                    this.$data.result2.push('no realizado')
                }
            })
            this.$data.r3.forEach((el,i)=>{
                if(this.$data.realizados3.indexOf(el) > -1){
                    this.$data.result3.push('realizado')
                }else{
                    this.$data.result3.push('no realizado')
                }
            })
            this.$data.r4.forEach((el,i)=>{
                if(this.$data.realizados4.indexOf(el) > -1){
                    this.$data.result4.push('realizado')
                }else{
                    this.$data.result4.push('no realizado')
                }
            })
           
            this.$data.respuesta.push(resolve.data.data.length)
            // this.$data.respuesta.push(0)

            // console.log(resolve.data.data.length)
           /* for(let k=0; k < this.$data.r1.length ;k++){
               console.log(this.$data.r1)
                for(let l=0; l < this.$data.realizados1.length ;l++){
                    if(this.$data.r1[k]===this.$data.realizados1[l]){
                            this.$data.r1.push('Realizado')
                    }else {
                            this.$data.r1.push('No Realizado')
                    }
                }
            }*/
          
          
        }).catch(error => console.log(error))


    },mounted(){
    //console.log(this.$store.state.user.nombre, this.$store.state.user.app_pat)
    }
})
export default class AdministracionDashboard extends Vue {
    
}
</script>

<style >
.capagrisdesafios{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(195, 195, 195, 0.87);
}
.v-progress-linear__content {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.click{
    cursor: pointer;
}
.click:hover{
   background: #e1e1e1;
   box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}
ul {
  list-style: none; /* Remove HTML bullets */
  padding: 0;
  margin: 0;
}

li { 
  padding-left: 16px; 
  display: flex;
}

li::before {
  content: "-"; /* Insert content that looks like bullets */
  padding-right: 8px; /* Or a color you prefer */
}
.btnclose{
    position: absolute !important;
    right: 0;
    border-radius: 25px;
    padding: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;

}
.fondo{
    background-color: #14E3BB !important;
}
.btnsabermas{
    background-color: #6DC9F7 !important;
    color: #fff !important;
    padding: 10px 15px;
    height: inherit;
}
.btnsabermas > div.v-btn__content{
    flex-wrap: wrap;
}
.btnsabermas > div.v-btn__content > div{
    width: 100%;
}
.positionabsolutedashboard{
    position: absolute;
    top: 0;
    left: 0 !important;
    z-index: 2;
    right: inherit !important;
}
.positionrelative{
    position: relative;
}
.progressradius{
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}

.text-center{
    text-align: center;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.circleetapa1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleetapamembresia1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.progress1{
     background: #B8EAE0;
}
.progressmembresia1{
    background: #14BC9B;
}
.progressmembresia2{
    background: #BE75F7;
}
.progressmembresia3{
    background: #FCAF3C;
}
.boredermembresiasbotom1{
 border-bottom: 2px solid #14BC9B;
}
.boredermembresiasbotom2{
    border-bottom: 2px solid #BE75F7;
}
.boredermembresiasbotom3{
    border-bottom: 2px solid #FCAF3C;
}
.boredermembresiasbotom4{
    border-bottom: 2px solid #FBABA0;
}

.circleetapa2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleetapamembresia2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.progress2{
     background: #BE75F7;
}
.circleetapa3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleetapamembresia3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.progress3{
     background: #FCAF22;
}
.colormembresia1{
    color: #24AE94 !important;
}
.colormembresia2{
    color: #BE75F7 !important;
}
.colormembresia3{
    color: #E0802C !important;
}
.circleetapa1, .circleetapa2, .circleetapa3 {
    position: relative;
}
.circleetapamembresia1, .circleetapamembresia2, .circleetapamembresia3{
    position: relative;
}
.circleetapa1 h3 {
    color: #24AE94;
}
.circleetapa2 h3 {
    color: #BE75F7;
}
.colorexpande{
 color: #FCAF22;   
}
.colorexpandeborde{
    border-bottom: 2px solid #FCAF22;  
}
.circleetapa3 h3 {
    color: #FCAF22;
}
.colornegro{
    color: #000 !important;
}
.colorblanco{
    color: #fff;
}
.colorcomienza{
    color: #24AE94;
}
.colorcrece{
    color: #BE75F7;
}
.colorcreceborde{
    color: #BE75F7;
    border-bottom: 2px solid #BE75F7;
}
.colorexpande{
    color:#FCAF22;
}
.fondogris{
    background: #F4F4F4;
    border-radius: 10px;
}
.fondogrismembresia{
    background: #F4F4F4 !important;
}
.fondodisabled{
    background-color: #B8F6EA !important;
}
.fondopuntos{
    background: #BAD8D2;
    width: 100%;
}
.btn-color{
    background:#92AFAF !important;
}
.btn-colorpendientes4{
    background:#AC2D1D !important;
}
.btn-colorpendientes3{
    background:#4F2981 !important;
}
.btn-colorpendientes{
      background:#D36523 !important;
}
.fondopendientes{
    background: #F7AA3A !important;
}
.fondodisabled2{
    background: #FCE5C2 !important;
}
.fondopendientes2{
    background:#F7AA33 !important;
    width: 100%;
}
.fondodisabled3{
    background: #E9DCFC !important;
}
.fondodisabled4{
    background: #FCD2CD !important;
}
.fondopuntospendientes{
    background:#D36523;
     width: 100%;
}
.fondopendientes4{
    background: #F86A58 !important;
}
.fondopendientes1{
    background: #1C7E6B;
    width: 100%;
}
.fondopendientes3{
     background: #B88AF7 !important;
}
.fondoprogress3{
    background: #4F2981 !important;
    width: 100%;
}
.fondoprogress4{
    background: #AC2D1D !important;
    width: 100%;
}
.fondoprogress1{
    background:#14BC9B;
}
.colorbeneficios{
    color: #3EAFD2;
}
.colorbeneficiosdisabled{
    color: #CCC7C7;
}
</style>